@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	font-family: "Inter", sans-serif;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	background-color: #f3f4f6;
}

input {
	padding: 0.4rem 0.6rem;
	border: 1px solid #cecece;
	border-radius: 0.2rem;
	outline: none;
}

.scrollbar::-webkit-scrollbar {
	width: 5px;
	/* Width of the scrollbar */
}

.scrollbar::-webkit-scrollbar-track {
	background: #efefef;
	/* Track color */
}

.scrollbar::-webkit-scrollbar-thumb {
	background: #b8b8b8;
	/* Handle color */
}

body::-webkit-scrollbar {
	width: 5px;
	/* Width of the scrollbar */
}

body::-webkit-scrollbar-track {
	background: #efefef;
	/* Track color */
}

body::-webkit-scrollbar-thumb {
	background: #8f8f8f;
	/* Handle color */
}

/* Add this to your global CSS file or inside a <style> tag */
.custom-scrollbar::-webkit-scrollbar {
	width: 4px;
	/* Width of the scrollbar */
	height: 4px;
	/* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	background-color: rgba(168, 168, 168, 0.5);
	/* Color of the scrollbar */
	border-radius: 0px;
	/* Roundness of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
	background-color: transparent;
	/* Background of the scrollbar track */
}

.font-xxs {
	font-size: 0.75rem;
}

.nowrow-tabel tr td {
	white-space: nowrap;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}


.video-comp video {
	height: 80vh;
}



.scanner-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
	position: relative;
}

.scanner-box {
	position: absolute;
	box-shadow: 0 0 100px rgba(0, 0, 0, 1);
	overflow: hidden;
}

.scanner-corner {
	width: 60px;
	height: 60px;
	position: absolute;
	border: 6px solid rgb(0, 55, 255);
	animation: cornerAnimation 1s infinite alternate ease-in-out;

}

.top-left {
	top: 20px;
	left: 20px;
	border-right: none;
	border-bottom: none;
	border-radius: 18px 0 0px 0px;
}

.top-right {
	top: 20px;
	right: 20px;
	border-left: none;
	border-bottom: none;
	border-radius: 0 18px 0px 0px;
}

.bottom-left {
	bottom: 20px;
	left: 20px;
	border-right: none;
	border-top: none;
	border-radius: 0 0 0px 18px;
}

.bottom-right {
	bottom: 20px;
	right: 20px;
	border-left: none;
	border-top: none;
	border-radius: 0 0 18px 0px;
}

@keyframes cornerAnimation {
	0% {
		width: 60px;
		height: 60px;
	}

	100% {
		width: 80px;
		height: 80px;
	}
}